
import { Component, Vue } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import EmptyView from "@/components/Common/EmptyView.vue";
import ServicesList from "@/components/Service/List.vue";

@Component({
  components: {
    PageSection,
    ServicesList,
    EmptyView,
  },
})
export default class ServiceIndex extends Vue {}
