
import { Component, Mixins, Ref } from "vue-property-decorator";
import RequestData from "@/store/types/RequestData";
import { RegisterHttp } from "@/utils/Decorators";
import WithErrors from "@/directives/WithErrors";
import { safeAsync } from "@/utils/AsyncUtil";
import ContractsAdminMixin, {
  ContractAdminTags,
} from "@/mixins/http/admin/ContractAdminMixin";
import InitiativeMixin from "@/mixins/http/InitiativeMixin";
import PageSection from "@/components/Common/PageSection.vue";
import { VNumericInputApplication } from "@/components/Common/VNumericInput.vue";
import VNumericInput from "@/components/Common/VNumericInput.vue";
import VFileUploader from "@/components/Common/VFileUploader.vue";
import ConfirmModal from "@/components/Common/VConfirmModal.vue";
import { Initiative } from "@/models";

@Component({
  components: {
    PageSection,
    VNumericInput,
    VFileUploader,
    ConfirmModal,
  },
  directives: {
    WithErrors,
  },
})
export default class ContractCreate extends Mixins(
  ContractsAdminMixin,
  InitiativeMixin
) {
  private startDate: Date | null = null;
  private endDate: Date | null = null;
  private file: any = null;

  private initiative: Initiative | null = null;

  @Ref() readonly confirmModal!: ConfirmModal;

  @RegisterHttp(ContractAdminTags.ContractCreate) httpRequest!: RequestData;

  async created() {
    const [data, errors] = await safeAsync(
      this.getInitiative(this.$route.params.id)
    );

    if (data) {
      this.initiative = data;
    }
  }

  get httpTag() {
    return ContractAdminTags.ContractCreate;
  }

  get currencyUsage() {
    return VNumericInputApplication.CURRENCY;
  }

  get fileName() {
    return this.file ? this.file.name : "";
  }

  get pointPerson() {
    return this.initiative.organization.pointPerson
      ? this.initiative.organization.pointPerson.fullname()
      : null;
  }

  private askForConfirm() {
    this.confirmModal.confirm(
      "Crea contratto esecutivo",
      "Creando un contratto esecutivo non sarà più possibile modificare la parte di iniziativa",
      ""
    );
  }

  get valid() {
    return this.startDate && this.endDate && this.file;
  }

  private async create() {
    const [data, errors] = await safeAsync(
      this.createContract(
        this.$route.params.id,
        this.startDate,
        this.endDate,
        this.file
      )
    );

    if (data) {
      this.$router.push({
        name: "admin_contract_show",
        params: { id: data.id },
      });
    }
  }
}
